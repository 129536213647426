import { useAuthStore } from '~/stores/auth'
import { defineNuxtRouteMiddleware } from 'nuxt/app'

const roleField = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'

export default defineNuxtRouteMiddleware((to, from) => {
  if (import.meta.client) {
    const authStore = useAuthStore()
    authStore.verifyAuth()

    if (!authStore.isAuthenticated) {
      // Redirect to login if not authenticated
      return navigateTo({ name: 'login' })
    } else if (
      !authStore.user.permissions &&
      to.path !== '/profile' &&
      to.path !== '/vet/profile/edit'
    ) {
      if (authStore.user[roleField] === 'Veterinarian') {
        return navigateTo({ path: '/vet/profile/edit' })
      } else {
        return navigateTo({ path: '/profile' })
      }
    } else if (
      to.path === '/welcome' &&
      authStore.user[roleField] === 'Veterinarian'
    ) {
      return navigateTo({ path: '/vet/welcome' })
    }
  }
})
